.hero-section{
  height:100vh;
  display: flex !important;
  align-items: flex-start;
}

p{
  text-transform: capitalize !important;
}

.hero-section .div{
  padding:20px;
  width:100%;
  min-height: 100px;
  margin-top:50px;

}

h1{
  margin-bottom:100px
}